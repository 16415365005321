import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "../../components/themeProvider";
import Navbar from "../../components/navbar";
import Home from "../home";
import Layout from "../layout";
import DashboardLayout from "../layout/dashboard";
import AnalyticsLayout from "../layout/analytics";
import ReportLayout from "../layout/report";
import AuthProvider from "../../components/authProvider";
import { SWRConfig } from "swr";
import { fetcher } from "../../state/axios";
import CompanyProvider from "../../components/companyProvider";
import { swrDefaultConfig } from "../../helpers/utils";
import TickerProvider from "../../components/tickerProvider";
import CacheProvider from "../../components/cacheProvider";
import BusinessSummaryLayout from "../../features/businessSummary/containers";
import ToastProvider from "../../components/toastProvider";
import CompanySwitchDialog from "../../features/multibrand/companySwitchDialog";

const App = () => {
  const location = useLocation();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (location && ["/", "/login/sso"].includes(location.pathname)) {
      navigateTo("/sales");
    }
  }, []);

  return (
    <ThemeProvider>
      <SWRConfig value={{ fetcher, ...swrDefaultConfig }}>
        <ToastProvider>
          <AuthProvider>
            <CompanySwitchDialog />
            <CompanyProvider>
              <Navbar />
              <TickerProvider>
                <Routes>
                  <Route
                    path="/business-summary"
                    element={<BusinessSummaryLayout />}
                  />
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path=":dashboard" element={<DashboardLayout />} />
                    <Route
                      path=":dashboard/:widget"
                      element={<AnalyticsLayout />}
                    />
                    <Route
                      path=":dashboard/:widget/report"
                      element={<ReportLayout />}
                    />
                  </Route>
                </Routes>
              </TickerProvider>
            </CompanyProvider>
          </AuthProvider>
        </ToastProvider>
      </SWRConfig>
    </ThemeProvider>
  );
};

export default App;
