import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DashboardDataContext from "../../helpers/contexts/dashboardDataContext";
import TickerContext from "../../helpers/contexts/tickerContext";
import useFetchDashboardData from "../../helpers/hooks/useFetchDashboardData";
import { RootState } from "../../state/store";
import { DashboardEventsContext } from "../dashboardEvents";
import { useParams } from "react-router-dom";
import { appEventsMessaging } from "../../helpers/hooks/pubSub";
import { dashboardsList } from "../../helpers/constants";
import { transformToWidgetsList, filterTickerDataFromWidgets } from "./utils";
import { createCompanyDateSelectionPresets } from "../../helpers/utils";

const DashboardDataProvider = ({ children, dashboard }) => {
  const [dashboardWidgets, setDashboardWidgets] = useState<any[]>([]);
  const { addToTickerContext } = useContext(TickerContext);
  const {
    setDashboard,
    setStartDate,
    setStartTime,
    updateBusinessStartDateTime,
  } = useContext(DashboardEventsContext);
  const [enableDashboardFetch, setEnableDashboardFetch] = useState(false);
  const dashboards = useSelector((state: RootState) => state.dashboard.dashboards);

  const dashboardsList = dashboards.map((d) => ({
    display_name: d.label,
    name: d?.api_path,
    route: `/${d?.api_path}`,
    api_endpoint: `/${d?.api_path}/dashboard`,
  }));

  const routeMatch = useParams();
  const shouldFetch = "dashboard" in routeMatch && enableDashboardFetch;

  const { filters, company } = useSelector((state: RootState) => state);

  const {
    data,
    isLoading: isDashboardDataLoading,
    error: dashboardDataLoadError,
    retry,
    dashboardLockedStatus,
  } = useFetchDashboardData({
    dashboard,
    filters,
    shouldFetch,
  });

  const dashboardRefreshEvent = useRef<{
    dashboard: string;
    subscription: { subscribe(): void; unsubscribe(): void };
  } | null>(null);

  useEffect(() => {
    if (dashboard) {
      if (
        dashboardRefreshEvent.current?.dashboard &&
        dashboard !== dashboardRefreshEvent.current?.dashboard
      ) {
        dashboardRefreshEvent.current.subscription.unsubscribe();
      }
      dashboardRefreshEvent.current = {
        dashboard,
        subscription: appEventsMessaging(`dashboard_updated_${dashboard}`, {
          onSubscribe(data) {
            retry();
          },
        }),
      };
      dashboardRefreshEvent.current?.subscription.subscribe();
    }
    return () => {
      dashboardRefreshEvent.current?.subscription.unsubscribe();
    };
  }, [dashboard]);

  const dashboardInfo = useMemo(() => {
    let dashboardData = dashboardsList.find((d) => d.name === dashboard);
    setEnableDashboardFetch(true);
    if (dashboardData) {
      return dashboardData;
    }
    return null;
  }, [dashboard]);

  useEffect(() => {
    if (data) {
      let { tickerData, updatedWidgetsList } = filterTickerDataFromWidgets(
        transformToWidgetsList(data)
      );
      let happeningsWidget = {
        widget: {
          type: "HAPPENINGS_EVENT_STREAM",
          title: "What's Happening",
          data: {},
        },
        is_timeless_widget: false,
        widget_visibility: "visible",
        url: "happenings-events-viewer",
        widgets: [],
        group: {},
        is_report_enabled: false,
        is_comparison_enabled: false,
        widget_name: "scheduled_orders",
      };
      setDashboardWidgets([happeningsWidget, ...updatedWidgetsList]);
      addToTickerContext(tickerData);
    }
  }, [data]);

  useEffect(() => {
    if (isDashboardDataLoading) {
      setDashboardWidgets([]);
      addToTickerContext([]);
    }
  }, [isDashboardDataLoading]);

  useEffect(() => {
    if (dashboardInfo && filters.dates[0] && filters.locations.length) {
      const { today: todayPreset } = createCompanyDateSelectionPresets({
        businessEndTimeInSecs: company.company_details?.business_end_time,
        timezoneOffset: company.company_details?.time_zone_offset,
      });
      updateBusinessStartDateTime(
        todayPreset.date_range[0],
        todayPreset.time_range[0]
      );
      setDashboard(dashboardInfo.name);
      setStartDate(filters.dates[0]);
      setStartTime(filters.timeRange[0]);
    }
  }, [
    dashboardInfo,
    filters.dates[0],
    filters.timeRange[0],
    filters.locations.length,
  ]);

  const refreshDashboardData = () => {
    retry(undefined, { revalidate: true });
  };

  const shouldEnableDashboardFetch = (shouldFetch = true) => {
    setEnableDashboardFetch(shouldFetch);
  };

  return (
    <DashboardDataContext.Provider
      value={{
        dashboardWidgets,
        dashboardInfo,
        isDashboardDataLoading,
        dashboardDataLoadError,
        refreshDashboardData,
        shouldEnableDashboardFetch,
        dashboardLockedStatus,
      }}>
      {children}
    </DashboardDataContext.Provider>
  );
};

export default DashboardDataProvider;
