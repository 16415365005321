import { createSlice } from "@reduxjs/toolkit";
import { getEnvValueFor } from "../../../helpers/utils";

const initialState = {
  dashboards: [],
  shouldDisableDashboardUpdatedEvents: JSON.parse(
    getEnvValueFor("DISABLE_DASHBOARD_AUTO_PUSH") || false
  ),
  pollingInterval:
    parseInt(getEnvValueFor("DASHBOARD_POLL_INTERVAL") || 9) * 1000,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardsList(state, action) {
      state.dashboards = action.payload;
    },
  },
});

export const { setDashboardsList } = dashboardSlice.actions;
export const reducer = dashboardSlice.reducer;
