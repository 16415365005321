import { useEffect } from "react";
import usePusher from "../../helpers/hooks/usePusher";
import { usePub } from "../../helpers/hooks/pubSub";

const CompanyLivePresenceEvents = ({ dashboardName, companyId }) => {
  const { pusherInstance } = usePusher();
  const publishMessage = usePub();
  const channelName = `presence-live-${dashboardName}_dashboard-company-${companyId}`;

  useEffect(() => {
    let livePresenceChannel = pusherInstance?.subscribe(channelName);
    livePresenceChannel?.bind("dashboard-updated", (data) => {
      publishMessage(`dashboard_updated_${dashboardName}`, undefined);
    });
    // Pusher Subscription Events
    livePresenceChannel?.bind("pusher:subscription_error", () => {
      console.error(`Pusher subscription error - ${channelName}`)
    });
    return () => {
      livePresenceChannel?.unsubscribe();
    };
  }, [channelName]);

  return <></>;
};

export default CompanyLivePresenceEvents;
