import { useContext, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { setCompany } from "../../state/features/company/slice";
import { setLocations } from "../../state/features/locations/slice";
import CompanyErrorScreen from "./errorScreen";
import CompanyLoadingScreen from "./loadingScreen";
import { useSearchParams } from "react-router-dom";
import {
  convertMinsToHrsAndMins,
  createCompanyDateSelectionPresets,
  serializeFilterQueryParams,
} from "../../helpers/utils";
import { setDateTimeFilter } from "../../state/features/filters/actions";
import { useDataWarehouseConfigurations } from "../../helpers/hooks/useDataWarehouseConfigurations";
import { useFetchCompanyData } from "../../helpers/hooks/useFetchCompanyData";
import { useFetchLocationsData } from "../../helpers/hooks/useFetchLocationsData";
import { useSimulateLoader } from "../../helpers/hooks/useSimulateLoader";
import DashboardMarketingContentView from "./dashboardMarketingContentView";
import { ENTITY_LOCK_TYPE } from "../../types/visibility";
import AuthTokenError from "../authTokenError";
import { useFetchDashboardList } from "../../helpers/hooks/useFetchDashboardsList";
import { setDashboardsList } from "../../state/features/dashboard/slice";
import { addToSentryContext, setSentryTags } from "../../services/sentry/utils";
import { DashboardEventsContext } from "../dashboardEvents";

let loadingStates = {
  1: "Connecting to Data Warehouse...",
  2: "Loading Company Profile...",
  3: "Fetching locations...",
  4: "Loading dashboard...",
  5: "Initializing...",
};

let loadErrorStates = {
  1: "Failed to connect to Data Warehouse. Please try again",
  2: "Failed to fetch company profile. Please try again",
  3: "Failed to fetch locations data. Please try again",
  4: "Failed to fetch dashboards data. Please try again or contact your administrator",
};

const CompanyProvider = ({ children }) => {
  let [queryParams, setQueryParams] = useSearchParams();
  const authorizedLocationIds = useRef([])
  const dispatch = useDispatch();
  const { updateCompanyId } = useContext(DashboardEventsContext);

  const configureCompanyInSentry = (data) => {
    try {
      const {
        user_id,
        user_name,
        company_id,
        company_name,
        country,
        time_zone,
        business_end_time,
      } = data;
      addToSentryContext({
        name: "Company Details",
        contexts: {
          user_id,
          user_name,
          company_id,
          company_name,
          country,
          time_zone,
          business_end_time,
        },
      });
      setSentryTags({ user_id, user_name, company_id, company_name });
    } catch (e) {
      console.error("Failed to set sentry company contexts");
    }
  };

  const {
    isDataWarehouseUp,
    isWarehouseLoading,
    isWarehouseLoadError,
    restartDataWarehouseCluster,
    vantageLockedStatus,
  } = useDataWarehouseConfigurations({
    maxRetries: 4,
    retryInterval: 10,
    onDataWarehouseUp() {
      fetchCompany();
    },
  });

  const {
    companyData,
    isCompanyDataLoading,
    companyDataLoadError,
    fetchCompany,
  } = useFetchCompanyData({
    onSuccess(data, key, config) {
      // set default date-time selection
      const { today: todayPreset } = createCompanyDateSelectionPresets({
        businessEndTimeInSecs: data?.business_end_time,
        timezoneOffset: data?.time_zone_offset,
      });
      let startRange = queryParams.get("from");
      let endRange = queryParams.get("to");
      let startDateRange = startRange?.split(".")[0] ?? todayPreset.date_range[0];
      let endDateRange = endRange?.split(".")[0] ?? todayPreset.date_range[1];
      let startTimeRange =  startRange?.split(".")[1];
      let endTimeRange =  endRange?.split(".")[1];
      if (startTimeRange) {
        startTimeRange = convertMinsToHrsAndMins(startTimeRange);
      } else {
        startTimeRange = todayPreset.time_range[0]
      }
      if (endTimeRange) {
        endTimeRange = convertMinsToHrsAndMins(endTimeRange);
      } else {
        endTimeRange = todayPreset.time_range[1];
      }
      setQueryParams(
        serializeFilterQueryParams({
          dateRange: [startDateRange, endDateRange],
          timeRange: [startTimeRange, endTimeRange],
        })
      );
      dispatch(
        setDateTimeFilter({
          dateRange: [startDateRange, endDateRange],
          timeRange: [startTimeRange, endTimeRange],
        })
      );
      updateCompanyId(data.company_id);
      dispatch(setCompany(data));
      configureCompanyInSentry(data);
      authorizedLocationIds.current = data?.authorized_location_ids || [];
      fetchLocations();
    },
  });

  const {
    locationData,
    isLocationDataLoading,
    locationDataLoadError,
    fetchLocations,
  } = useFetchLocationsData({
    onSuccess(data, key, config) {
      if (authorizedLocationIds.current.length > 0) {
        dispatch(
          setLocations(
            data.filter((l) => authorizedLocationIds.current.includes(l.id))
          )
        );
      } else {
        dispatch(setLocations(data));
      }
      fetchDashboardList();
    },
  });

  const {
    dashboardsListData,
    isDashboardListDataLoading,
    dashboardListDataFetchError,
    fetchDashboardList,
  } = useFetchDashboardList({
    onSuccess(data, key, config) {
      dispatch(setDashboardsList(data));
      simulateLoader();
    },
  });

  const {
    isLoaded: simulateLoaded,
    isLoading: simulatedLoading,
    reload: simulateLoader,
  } = useSimulateLoader({
    duration: 2,
    startLoadingOnMount: false,
  });

  const progress = useMemo(() => {
    let dataSequence = [
      true,
      isDataWarehouseUp,
      Boolean(companyData),
      Boolean(locationData),
      Boolean(dashboardsListData),
      simulateLoaded,
    ];
    let loadingSequence = [
      isWarehouseLoading,
      isCompanyDataLoading,
      isLocationDataLoading,
      isDashboardListDataLoading,
      simulatedLoading,
    ];
    let isLoading = loadingSequence.includes(true);
    let loadedState = dataSequence.lastIndexOf(true) + 1;
    let statusText = loadingStates[loadedState];
    return {
      isLoading,
      progressCount: (loadedState / 4) * 100,
      statusText,
    };
  }, [
    isDataWarehouseUp,
    isWarehouseLoading,
    companyData,
    isCompanyDataLoading,
    locationData,
    isLocationDataLoading,
    dashboardsListData,
    isDashboardListDataLoading,
    simulateLoaded,
    simulatedLoading,
  ]);

  const appLoadError = useMemo(() => {
    let isCompanyLoadError = !companyData && companyDataLoadError;
    let isLocationsLoadError = !locationData && locationDataLoadError;
    let isDashboardsLoadError = !dashboardsListData && dashboardListDataFetchError;
    let errorSequence = [
      isWarehouseLoadError,
      isCompanyLoadError,
      isLocationsLoadError,
      isDashboardsLoadError,
    ];
    let errorStateIndex = errorSequence.lastIndexOf(true);
    let statusText = loadErrorStates[errorStateIndex + 1];
    let isError = errorSequence.includes(true);
    return {
      isError,
      statusText,
    };
  }, [
    isWarehouseLoadError,
    companyData,
    companyDataLoadError,
    locationData,
    locationDataLoadError,
    dashboardsListData,
    dashboardListDataFetchError,
  ]);

  if (vantageLockedStatus.isEnabled) {
    if (
      vantageLockedStatus.config.lockType === ENTITY_LOCK_TYPE.MARKETING_MESSAGE
    ) {
      return (
        <DashboardMarketingContentView
          url={vantageLockedStatus?.config?.marketingHtmlUrl}
        />
      );
    }
    if (vantageLockedStatus.config.lockType === ENTITY_LOCK_TYPE.UNAUTHORIZED) {
      return <AuthTokenError />;
    }
  }

  if (progress.isLoading) {
    return <CompanyLoadingScreen statusText={progress.statusText} />;
  }

  if (appLoadError.isError) {
    return <CompanyErrorScreen errorText={appLoadError} />;
  }

  return companyData && locationData && dashboardsListData && simulateLoaded ? (
    <>{children}</>
  ) : null;
};

export default CompanyProvider;
